<script>
  import StarsBlack from '/graphics/StarsBlack.svelte'
  export let title;
</script>

<div class="form-page-header">
  <div class='g-content-width'>
    <div class="star-group"><StarsBlack /></div>
    <div class="copy">
      <h1>{title}</h1>
      <p>
        <slot></slot>
      </p>
    </div>
    <div class="star-group"><StarsBlack /></div>
  </div>
</div>


<style lang='less'>.form-page-header {
  margin: 2.2em auto 4em auto;
  text-align: center;
  position: relative;
}
.form-page-header .g-content-width {
  position: relative;
  max-width: 54em;
}
.form-page-header .star-group {
  width: 5.375em;
  height: 5.375em;
  position: absolute;
  left: 3em;
  top: 1.5em;
  z-index: -1;
}
@media screen and (max-width: 700px) {
  .form-page-header .star-group {
    display: none;
  }
}
.form-page-header .star-group:last-of-type {
  left: unset;
  top: unset;
  right: 1.5em;
  top: -1.5em;
}
.form-page-header .copy {
  width: 60%;
  margin: 0 auto;
}
@media screen and (max-width: 700px) {
  .form-page-header .copy {
    width: 100%;
  }
}
</style>