<button on:click ><slot></slot></button>

<style>
  button {
    background: none;
    outline: none;
    border: none;
    padding: 0;
    cursor: pointer;
  }
</style>
