<script>
  import Modal from '/modal/Modal.svelte'
  import Input from '/elements/inputs/Input.svelte'
  import Loading from '/elements/Loading.svelte'
  import fetch from '/util/fetch'
  import url from '/util/url'
  import routeTo from '/router/route'
  import {modal, modalState} from '/store'
</script>

<Modal>
  <div>
    <h3>API Key Created</h3>
    <p>
    Your API key was created successfully. Please save this key somewhere because we won't be able to show it to you again.
    </p>
    <pre>{$modalState.apiKey}</pre>
    <button class='g-button-primary' on:click={() => { modalState.set(null); modal.set(null) }} >Ok, I've saved it.</button>
  </div>
</Modal>

<style lang='less' >div {
  max-width: 20em;
  margin: 0 auto;
  display: flex;
  flex-flow: column nowrap;
}
div > * {
  margin-bottom: 2.5rem;
  flex-shrink: 1;
}
div > *:not(:first-child) {
  margin-top: 0;
}
div p {
  text-align: center;
}
</style>
