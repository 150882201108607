<script>
  export let title
  export let icon
  export let spaceBetween = false
</script>

<div class='section-header' class:spaceBetween style={`--icon: ${icon}`} >
  <h2>{title}</h2>
  <slot></slot>
</div>

<style lang='less'>.section-header {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin-bottom: 2em;
}
@media screen and (max-width: 850px) {
  .section-header {
    display: block;
  }
}
.section-header.spaceBetween {
  justify-content: space-between;
}
.section-header :global(.g-button-primary) {
  margin-left: 1em;
}
.section-header :global(.g-button-primary):first-child {
  margin-left: 3em;
}
@media screen and (max-width: 850px) {
  .section-header :global(.g-button-primary):first-child {
    margin-left: 0;
  }
}
h2 {
  font-size: 1.4em;
  font-weight: 400;
  display: flex;
  align-items: center;
  margin: 0;
}
@media screen and (max-width: 700px) {
  h2 {
    margin-bottom: 0.5em;
  }
}
h2:before {
  content: '';
  display: inline-block;
  height: 0.8em;
  width: 0.8em;
  margin-right: 0.5em;
  background-image: var(--icon);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
:global(.action) {
  background-repeat: no-repeat;
  background-size: 1em 1em;
  background-position: left center;
  padding-left: 1.5em;
  text-decoration: none;
  color: #696969;
  margin-left: 3.5em;
  font-size: 0.85em;
}
@media screen and (max-width: 700px) {
  :global(.action) {
    margin: 0;
  }
}
:global(.action):hover {
  opacity: 0.7;
}
:global(.action).edit {
  background-image: url(/assets/icons/icon-edit.svg);
}
:global(.action).add {
  background-image: url(/assets/icons/icon-add.svg);
}
:global(.level) {
  display: flex;
  align-items: stretch;
}
@media screen and (max-width: 850px) {
  :global(.level) {
    margin-top: 1.25em;
  }
}
</style>
