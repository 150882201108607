<script>
  export let value = ''
  export let error = ''
</script>

<div>
  <input {...$$props} bind:value />
  {#if error}
    <span>{error}</span>
  {/if}
</div>

<style lang='less' >input {
  font-size: 1em;
  border: none;
  border-bottom: 1px solid black;
  padding-bottom: 1em;
  width: 100%;
}
input:focus {
  outline: none;
  border-bottom-color: var(--primary-accent-color);
}
span {
  color: red;
  margin-top: 0.2em;
  font-size: 0.8em;
}
</style>
