<script>
  import pipe from '@wasmuth/pipe'
  import path from '@wasmuth/path'
  import SectionHeader from '/elements/SectionHeader.svelte'
  import url from '/util/url'
  import { modal } from '/store'
  import routeTo from '/router/route'
  import ActivatePlanForm from './ActivatePlanForm.svelte'
  import useRequest from '/util/useRequest.js'
  export let account

  let submittingActivatePlan = false
  let card

  $: card = account && pipe(
    account => ({
      cards: path(['stripeCustomerData', 'sources', 'data'], account) || [],
      defaultSource: path(['stripeCustomerData', 'defaultSource'], account)
    }),
    ({ cards, defaultSource }) => cards.find(c => c.id === defaultSource)
  )(account)

  $: {
    if (account && !card) {
      routeTo(url('paymentDetails'))
    }
  }
</script>

<svelte:head>
  <title>Billing Overview | Moonroof</title>
  <meta name="description" content="Performance enhancement toolkit for Python and Django developers." />
</svelte:head>

<div>
  <SectionHeader title='Billing' icon='url(/assets/Stairs.svg)' spaceBetween>
    {#if card}
      <a class='action edit' href={url('paymentDetails')}>Edit Payment</a>
    {:else}
      <a class='action add' href={url('paymentDetails')}>Add Payment Method</a>
    {/if}
  </SectionHeader>

  <div class='body'>
    {#if card}
      <div class='card'>
        <span class={`type ${card.brand.replace(/\s/g, '')}`}>{card.brand}</span>
        <span class='number'>{card.last4}</span>
        <span class='expiration'>
          {`${card.expMonth}`.padStart(2, '0')}/{`${card.expYear}`.slice(2)}
        </span>
      </div>
    {/if}

    <div class='actions'>
      <div class='buttons'>
        <button
          class='g-button-primary small-button'
          on:click={() => modal.set('ModalInvoices')}>
          Payment History
        </button>
        {#if !account.stripeIsPaid}
          <ActivatePlanForm />
        {:else}
          <button
            class='g-button-primary small-button'
            on:click={() => modal.set('ModalConfirmUnsubscribe')}>
            Cancel Plan
          </button>
        {/if}
      </div>
      <!--p>Next Payment: $25.00CAD on June 12, 2020</p-->
    </div>

  </div>
</div>

<style lang='less'>.body {
  display: flex;
  align-items: flex-start;
  flex-flow: row wrap;
  justify-content: space-between;
}
@media screen and (max-width: 1060px) {
  .body {
    display: block;
  }
}
.card {
  height: 11.438em;
  width: 17.875em;
  border-radius: 10px;
  background-color: var(--primary-accent-color);
  position: relative;
  color: white;
}
@media screen and (max-width: 1175px) {
  .card {
    font-size: 120%;
  }
}
.type {
  position: absolute;
  top: 1.75em;
  right: 1.75em;
  text-indent: -9999px;
  width: 7.5em;
  height: 1.25em;
  background-repeat: no-repeat;
  background-position: right center;
  background-size: contain;
}
.type.Visa {
  background-image: url(/assets/logo-visa.png);
}
.type.AmericanExpress {
  background-image: url(/assets/logo-americanexpress.png);
}
.type.MasterCard {
  background-image: url(/assets/logo-mastercard.png);
}
.type.Discover {
  background-image: url(/assets/logo-discover.png);
}
.number:before {
  content: '**** **** **** ';
}
.number {
  position: absolute;
  top: 8em;
  left: 1.3em;
  font-size: 0.875em;
}
.expiration {
  position: absolute;
  top: 11.5em;
  left: 1.3em;
  font-size: 0.75em;
}
form {
  display: inline-block;
}
.actions {
  flex: 1;
  padding-left: 5%;
}
@media screen and (max-width: 1060px) {
  .actions {
    padding: 0;
    margin: 2em 0;
  }
}
.actions p {
  font-size: 0.9em;
  margin: 0;
  text-align: right;
}
.actions .buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2em;
}
.actions button {
  width: 48%;
}
</style>
