<script>
  import { LottiePlayer } from '@lottiefiles/svelte-lottie-player';
  export let darkBackground = true;

</script>

<style lang='less'>.lottie-logo {
  width: 260px;
  margin-left: -5.5em;
  margin-top: -0.5em;
  height: 46px;
  display: flex;
}
@media (max-width: 1500px) {
  .lottie-logo {
    margin-left: 0;
  }
}
@media (max-width: 800px) {
  .lottie-logo {
    width: 200px;
    margin-left: -0.5em;
    height: auto;
  }
}
@media (max-width: 600px) {
  .lottie-logo {
    width: 150px;
  }
}
@media (max-width: 320px) {
  .lottie-logo {
    width: 130px;
  }
}
</style>

<div class='lottie-logo'>

{#if darkBackground}
<LottiePlayer
  src="/assets/lottie/logowhite.json"
  autoplay="{true}"
  loop="{false}"
  controls="{false}"
  renderer="svg"
  background="transparent"
  width=''
  height=''
  controlsLayout
/>
{:else}
<LottiePlayer
  src="/assets/lottie/logoblack.json"
  autoplay="{true}"
  loop="{false}"
  controls="{false}"
  renderer="svg"
  background="transparent"
  width=''
  height=''
  controlsLayout
/>
{/if}

</div>
