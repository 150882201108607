<script>
  import {apiKey as apiKeyStore} from '/store'
  import SecondaryAction from '/elements/SecondaryAction.svelte'
  import Loading from '/elements/Loading.svelte'
  import url from '/util/url'
  let apiKey
  let submitting = false
  let apiKeyError
  let globalError
  import localStorage from '/util/localStorage'

  const getStats = async () => {
    try {
      const response = await fetch(
        url('api.stats', {}, {limit: 15}),
        {headers: {Authorization: `Bearer ${apiKey}`}}
      )
      let json = await response.json()
      if (response.status > 299) {
        throw response.status
      }
      return json
    } catch (err) {
      throw err
    }
  }

  const onSubmit = (ev) => {
    ev.preventDefault()
    submitting = true
    globalError = false
    apiKeyError = false
    getStats()
      .then((res) => {
        apiKeyStore.set(apiKey)
        localStorage.setItem('apiKey', apiKey)
      })
      .catch((err) => {
        if (err === 401) {
          apiKeyError = 'Invalid API key'
        } else {
          globalError = 'An unknown error occured, please try again later'
        }
      })
      .finally(() => {
        submitting = false
      })
  }
</script>

<svelte:head>
  <title>Stats | Moonroof</title>
</svelte:head>


{#if globalError}
  <span class='global-error' >{globalError}</span>
{/if}

<form class='api-key-form' on:submit={onSubmit} >
  <input type='password' placeholder='API Key' bind:value={apiKey} />
  {#if apiKeyError}
    <span class='error' >{apiKeyError}</span>
  {/if}
  <button disabled={!apiKey || submitting} class='g-button-primary' >
    {#if submitting}
      <Loading />
    {:else}
      View stats
    {/if}
  </button>
  <SecondaryAction>
    <span>Don't have an API Key? </span>
    <a href={url('noUserGetStarted')} > Get one here.</a>
  </SecondaryAction>
</form>

<style lang='less' >form {
  display: flex;
  flex-flow: column nowrap;
  max-width: 20em;
  margin-top: 3em;
  margin-bottom: 6em;
}
input {
  background: none;
  transition: border-bottom 200ms;
  border: none;
  border-bottom: 1px solid var(--border-color);
  padding: 0.25em 0;
  font-size: 1.3em;
}
input:focus {
  border-bottom: 1px solid var(--primary-accent-color);
}
span.error {
  color: red;
}
button {
  margin: 1em 0;
}
</style>
