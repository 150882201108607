<div class='c' >
  <div class='table'>
    <slot></slot>
  </div>
</div>

<style lang='less'>.c {
  overflow: auto;
  flex-shrink: 1;
}
.table {
  min-width: calc(100% - 2px);
  display: table;
  background: none;
  border-radius: 0.5em;
  margin: 0 auto;
  border-collapse: collapse;
}
@media screen and (max-width: 500px) {
  .table {
    font-size: 75%;
  }
}
.table > :global(*) {
  display: table-row;
}
.table > :global(* > *) {
  display: table-cell;
  vertical-align: middle;
  padding: 1em 0.5em;
  text-align: center;
  border: 1px solid var(--table-border-color);
}
.table > :global(*:first-child > *) {
  color: var(--table-header-text-color);
  background-color: var(--primary-accent-color);
  font-weight: 400;
}
.table > :global(a) {
  text-decoration: none;
}
@media (hover: hover) {
  .table :global(a:hover) {
    background-color: var(--table-hover-background-color);
    color: var(--primary-accent-color);
    cursor: pointer;
  }
}
.table :global(.endpoint-url) {
  max-width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  background: url(/assets/link-arrow.svg) no-repeat right center;
  background-size: 0.7em 0.7em;
  padding-right: 1.25em;
  font-weight: 700;
}
.table :global(.table-empty-row) {
  text-align: center;
  font-style: italic;
  padding: 2em 1em;
}
.table :global(.table-empty-row > td > a) {
  text-decoration: underline;
}
@media (hover: hover) {
  .table :global(.table-empty-row > td > a:hover) {
    color: var(--primary-accent-color);
    background: none;
  }
}
</style>
