<div>
  <slot></slot>
</div>

<style>
  div {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
  }

  div > :global(*:first-child) {
    color: #777;
    font-style: italic;
  }

  div > :global(*:last-child) {
    text-decoration: underline;
    background: none;
    border: none;
    font-size: 1em;
    padding: 0;
    margin-left: 0.5em;
    transition: color 200ms;
    cursor: pointer;

  }

  @media(hover) {
    div > :global(*:last-child:hover) {
      color: var(--primary-accent-color);
    }
  }

</style>
