<script>
  export let dark
  export let heading
  export let image
</script>

<div class='hero' class:dark>
  <div class='g-content-width'>

    <div class='text'>
      <h2>{heading}</h2>
      <slot />
    </div>

    <div class='image'>
      <img src={`/assets/${image}`} alt={heading} />
    </div>
  </div>
</div>

<style lang='less'>.hero {
  padding-bottom: 3.6em;
  border-bottom: solid 1px var(--border-color);
  margin-bottom: 3em;
  color: var(--text-color);
}
@media screen and (max-width: 600px) {
  .hero {
    font-size: 90%;
  }
}
.hero .g-content-width {
  display: flex;
  align-items: center;
}
.hero .text {
  width: 36%;
}
@media screen and (max-width: 800px) {
  .hero .text {
    width: 60%;
  }
}
@media screen and (max-width: 600px) {
  .hero .text {
    width: 100%;
    max-width: 400px;
  }
}
.hero .text h2 {
  color: var(--primary-accent-color);
  font-family: 'SpaceMono';
  font-weight: normal;
  font-size: 2.2em;
  margin: 0;
}
.hero .text h2:after {
  display: block;
  content: '';
  width: 5em;
  height: 3px;
  margin: 0.5em 0 1em;
  background-color: var(--text-color);
}
.hero .text :global(p) {
  font-size: 1.15em;
  line-height: 1.4;
  margin: 0 0 1em 0;
}
.hero .image {
  width: 39%;
  margin-left: 18.5%;
}
@media screen and (max-width: 600px) {
  .hero .image {
    display: none;
  }
}
.hero .image img {
  width: 100%;
  height: auto;
}
.hero.dark {
  background-color: var(--dark-background-color);
  border-bottom: none;
  color: white;
}
.hero.dark h2 {
  color: white;
}
.hero.dark h2:after {
  background-color: white;
}
</style>
