
<div class="form-page-form-container">
  <div class='g-content-width'>
    <slot></slot>
  </div>
</div>


<style lang='less'>.form-page-form-container :global(form) {
  max-width: 25em;
  padding: 0 1em;
  margin: 0 auto;
  text-align: center;
}
.form-page-form-container :global(.double-row) {
  display: flex;
  flex-flow: row wrap;
  justify-content: fill;
  flex-grow: 1;
}
.form-page-form-container :global(.form-row) {
  flex-grow: 1;
  margin-bottom: 2em;
}
.form-page-form-container :global(.double-row :first-child) {
  margin-right: 1em;
}
.form-page-form-container :global(p) {
  max-width: 22em;
  margin: 0 auto;
  font-size: 0.8em;
  line-height: 1.6em;
  color: var(--muted-text-color);
}
</style>