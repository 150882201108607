<script>
  import Logo from '/elements/Logo.svelte'
  import url from '/util/url'
  import {route, token, apiKey} from '/store'
  import Account from './Account.svelte'
</script>

<div class:dark={['landing', 'pricing'].includes(($route.definition || {}).name)} class:has-border={['account'].includes(($route.definition || {}).name)} >
  <div class='g-content-width' >
    <a href={url('landing')}><Logo darkBackground={['landing', 'pricing'].includes(($route.definition || {}).name)} /></a>
    <nav class="main">
      <ul>
        {#if !$token}
          <li><a href={url('pricing')}>Pricing</a></li>
        {/if}
        <li>
          {#if $token}
            <Account />
          {:else if $apiKey && !($route.definition || {name: ''}).name.toLowerCase().includes('stats')}
            <a href={url('noUserStats')} class="g-button-primary">View my stats</a>
          {:else}
            <a href={url('signup')} class='g-button-primary' >Create an Account</a>
          {/if}
        </li>
      </ul>
    </nav>
  </div>
</div>

<style lang='less'>div > div {
  padding-top: 3em;
  padding-bottom: 3em;
}
.has-border {
  border-bottom: solid 1px var(--border-color);
}
.g-content-width {
  display: flex;
  justify-content: space-between;
}
@media (max-width: 600px) {
  nav.main {
    font-size: 85%;
  }
}
nav.main ul {
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
}
@media (max-width: 950px) {
  nav.main ul {
    flex-direction: column;
    align-items: flex-end;
  }
}
nav.main li {
  list-style: none;
  margin: 0;
  text-transform: uppercase;
  font: 1.1em "SpaceMono";
}
nav.main li + li {
  margin-left: 2.55em;
}
@media (max-width: 950px) {
  nav.main li + li {
    margin-left: 0;
    margin-top: 0.75em;
  }
}
nav.main a {
  color: var(--primary-accent-color);
  text-decoration: none;
}
nav.main a:hover {
  color: var(--secondary-accent-color);
}
nav.main .g-button-primary {
  padding: 0.5rem 2.3rem;
  font-size: 0.9em;
}
@media (max-width: 600px) {
  nav.main .g-button-primary {
    display: none;
  }
}
nav.main .g-button-primary:hover {
  color: white;
}
.dark {
  background-color: var(--dark-background-color);
}
.dark nav.main a:not(.g-button-primary) {
  color: white;
}
.dark nav.main a:not(.g-button-primary):hover {
  color: var(--primary-accent-color);
}
.dark nav.main .g-button-primary {
  color: var(--primary-accent-color);
}
.dark nav.main .g-button-primary:hover {
  color: white;
}
div.g-content-width {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
}
/* div.g-content-width  > * { */
/*   margin-bottom: 1em; */
/* } */
</style>



