<script>
  export let name = 'toggle'
  export let options = [{label: 'On', value: 'on'}, {label: 'Off', value: 'off'}]
  export let value = ''
</script>

<div style={`--toggle-count: ${options.length};`} >
  {#each options as option}
    <label>
      <input
        bind:group={value}
        type='radio'
        name={name}
        value={option.value}
      />
      <span><span>{option.label}</span></span>
    </label>
  {/each}
</div>

<style lang='less'>div {
  border: 1px solid var(--dark-border-color);
  display: grid;
  grid-template-columns: repeat(var(--toggle-count), 1fr);
  border-radius: 2em;
  overflow: hidden;
}
label {
  cursor: pointer;
}
@media (hover) {
  label:hover input:not(:checked) + span {
    background: var(--element-hover-background-color);
    color: var(--primary-accent-color);
  }
}
label span {
  white-space: nowrap;
  font-size: 0.9rem;
}
@media screen and (max-width: 500px) {
  label span {
    font-size: 0.7rem;
  }
}
label > span {
  display: block;
  padding: 0 1.5em;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
input {
  position: absolute;
  left: -10000px;
}
input:checked + span {
  color: var(--toggle-on-text-color);
  background: var(--primary-accent-color);
}
</style>
