<script>
  import IntersectionObserver from '/elements/IntersectionObserver.svelte'
  import { LottiePlayer } from '@lottiefiles/svelte-lottie-player';
  export let heading;
  export let lottieFile;
  export let alternate;
  export let extraClass;
</script>

<div class={`featurette ${alternate ? 'alternate' : ''} ${extraClass ? extraClass : ''}`}>
  <div class='g-content-width'>
    <div class='lottie'>

      <IntersectionObserver let:intersecting top={0}>

          <LottiePlayer
            src={lottieFile}
            autoplay={{intersecting}}
            loop={true}
            controls={false}
            renderer="svg"
            width=''
            height=''
            background="transparent"
            controlsLayout
          />

      </IntersectionObserver>

    </div>
    <div class='content'>
      <h2>{heading}</h2>
      <p>
        <slot />
      </p>
    </div>
  </div>
</div>

<style lang='less'>.featurette {
  color: var(--dark-background-color);
  text-align: right;
  padding: 3em 0;
}
@media screen and (max-width: 600px) {
  .featurette {
    font-size: 85%;
  }
}
.featurette .lottie {
  width: 44%;
  margin-left: 2em;
}
@media only screen and (max-width: 768px) {
  .featurette .lottie {
    width: 37%;
  }
}
@media only screen and (max-width: 600px) {
  .featurette .lottie {
    width: 100%;
    max-width: 400px;
    margin-bottom: 2em;
    margin-left: 0;
  }
}
@media only screen and (min-width: 768px) {
  .featurette.loop1 {
    padding: 4em 0;
  }
  .featurette.loop1 .lottie {
    width: 50%;
    min-height: 426px;
  }
  .featurette.loop2 {
    padding: 7.5em 0;
  }
  .featurette.loop2 .lottie {
    width: 63%;
    min-height: 320px;
  }
  .featurette.loop3 .lottie {
    width: 44%;
    min-height: 493px;
  }
}
.featurette.alternate {
  background-color: var(--dark-background-color);
  color: white;
  text-align: left;
}
.featurette.alternate .lottie {
  order: 2;
}
.featurette.alternate h2 {
  align-items: flex-start;
  margin-left: unset;
}
.featurette.alternate h2:after {
  background-color: var(--secondary-accent-color);
}
.featurette.alternate p {
  margin-left: unset;
}
.featurette .g-content-width {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media only screen and (max-width: 600px) {
  .featurette .g-content-width {
    flex-direction: column;
  }
  .featurette .g-content-width .content {
    order: 2;
  }
}
.featurette .content {
  flex: 1;
  max-width: 490px;
}
.featurette h2,
.featurette p {
  color: currentColor;
  padding: 0;
  margin: 0;
}
.featurette h2 {
  font-size: 2.2em;
  line-height: 1.5;
  margin-left: auto;
  font-weight: normal;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
@media only screen and (max-width: 600px) {
  .featurette h2 {
    line-height: 1.1;
    max-width: 250px;
  }
}
.featurette h2:after {
  display: block;
  content: '';
  width: 5em;
  height: 3px;
  margin: 0.5em 0 1em;
  background-color: var(--primary-accent-color);
}
.featurette p {
  font-size: 1.26em;
  line-height: 1.5;
  margin: 0;
  margin-left: auto;
}
@media only screen and (max-width: 600px) {
  .featurette p {
    font-size: 1.15em;
    line-height: 1.3;
  }
}
</style>
