<script>
  import { apiKey } from '/store'
  import Router from '/router/Router.svelte'
  import Graphic1 from '/graphics/Graphic1.svelte'
  import Stars from '/graphics/Stars.svelte'
  import Logo from '/elements/Logo.svelte'
  import FeaturetteChart from './FeaturetteChart.svelte'
  import FeaturetteLottie from './FeaturetteLottie.svelte'
  import FeaturettePoint from './FeaturettePoint.svelte'
  import FeaturetteWithPointsLottie from './FeaturetteWithPointsLottie.svelte'
  import CtaGetStarted from '/elements/CtaGetStarted.svelte'
  import { LottiePlayer } from '@lottiefiles/svelte-lottie-player'
  import IntersectionObserver from '/elements/IntersectionObserver.svelte'
  import url from '/util/url'
</script>

<svelte:head>
  <title>Moonroof | Insights to make your Django app faster</title>
  <meta name="description" content="Performance enhancement toolkit for Python and Django developers." />
</svelte:head>

<div class='block dark first'>
  <div class='g-content-width'>
    <div class='content'>
      <h1>Insights to make your Django app faster</h1>
      <h2>
        Performance enhancement toolkit for Python and Django developers.
      </h2>
      {#if $apiKey}
        <a href={url('signup')} class='g-button-primary' >Create an account</a>
      {:else}
        <a href={url('noUserGetStarted')} class='g-button-primary'>Get API Key</a>
        <h3>Try it free &mdash; no signup required!</h3>
      {/if}
      <div class='stars'>
        <Stars />
      </div>
    </div>
    <div class='graphic'>
      <IntersectionObserver let:intersecting top={0}>

          <LottiePlayer
            src='/assets/lottie/loop4.json'
            autoplay={{intersecting}}
            loop={true}
            controls={false}
            renderer="svg"
            background="transparent"
            height=''
            width=''
            controlsLayout
          />

      </IntersectionObserver>
      <div class='stars'>
        <Stars />
      </div>
    </div>
  </div>
</div>

<FeaturetteWithPointsLottie
  headline='A deeper look inside your Django app'
  introText='Look at all the tried and true areas for Django performance
  enhancements and gain actionable insights to correct any issues in real-time.'
  lottieFile='/assets/lottie/loop5.json'>

  <FeaturettePoint heading='Endpoints' image='point-response-type.png'>
    Monitor endpoints and know exactly which ones are bogging your interface down.
  </FeaturettePoint>

  <FeaturettePoint heading='SQL Queries' image='point-performance.png'>
    Know if you’re needlessly looping through thousands of records and fix it.
  </FeaturettePoint>

  <FeaturettePoint heading='Response Times' image='point-event-timeline.png'>
    Improve page loading by identifying slow page response times.
  </FeaturettePoint>

</FeaturetteWithPointsLottie>

<FeaturetteChart image='chart-pot.png' />

<FeaturetteLottie heading='Make interfaces more magical' lottieFile='/assets/lottie/loop1.json' alternate={false} extraClass='loop1'>
  Faster page load times mean visitors stick around and take action. Track page
  by page performance and improve on the fly.
</FeaturetteLottie>

<FeaturetteLottie heading='Iluminate Data' lottieFile='/assets/lottie/loop3.json' alternate extraClass='loop3'>
  Data is good, but actionable insight is better. Moonroof will guide you to
  enhance the performance of your queries and endpoints.
</FeaturetteLottie>

<FeaturetteLottie heading='Find bottlenecks' lottieFile='/assets/lottie/loop2.json' alternate={false} extraClass='loop2'>
  Database query doing a weird loop? Endpoints slowing down the whole page?
  Easily find issues and fix them.
</FeaturetteLottie>

<CtaGetStarted dark />

<style lang='less'>.block {
  height: calc(100vh - 136px);
  max-height: 760px;
  display: flex;
  box-sizing: border-box;
}
@media screen and (max-width: 600px) {
  .block {
    padding: 0 0 4em 0;
    font-size: 90%;
  }
}
.block .g-content-width {
  justify-content: space-between;
  align-items: center;
  width: 90%;
  padding: 2em 0;
}
.block h1,
.block h2,
.block h3 {
  margin-top: 0;
  font-weight: 400;
}
.block h1 {
  margin-bottom: 1.2em;
  font-size: 2.15em;
}
.block h2 {
  margin-bottom: 4em;
  font-size: 1.2em;
  line-height: 1.5;
}
@media screen and (max-width: 600px) {
  .block h2 {
    font-size: 1.3em;
  }
}
.block h3 {
  font-size: 1em;
}
.block h2,
.block h3 {
  font-family: 'WorkSans';
}
.block > div {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}
.block > div > div > * {
  margin-bottom: 1em;
}
.block .content {
  max-width: 30em;
  margin-right: 2em;
}
@media screen and (max-width: 600px) {
  .block .content {
    margin-right: 0;
  }
}
.block .graphic {
  margin-left: 1em;
  width: 50%;
  min-height: 330px;
}
@media only screen and (max-width: 800px) {
  .block .graphic {
    display: none;
  }
}
.dark {
  background: black;
}
.dark h1,
.dark h2,
.dark h3 {
  color: white;
}
.content,
.graphic {
  position: relative;
}
.content .stars {
  position: absolute;
  right: 1em;
  bottom: 2em;
}
.graphic .stars {
  position: absolute;
  right: 1em;
  top: 1em;
}
.screenshot > img {
  max-width: 50em;
  margin: 0 auto;
}
</style>
