<h3>
  <slot></slot>
</h3>

<style>
  h3 {
    text-align: center;
    padding: 10em 1em 5em 1em;
  }
</style>
