<script>
  export let heading
  export let image
</script>

<div class='point'>
  <img src={`/assets/${image}`} alt={heading} />
  <div class='text'>
    <h2>{heading}</h2>
    <p>
      <slot />
    </p>
  </div>
</div>

<style lang='less'>.point {
  display: flex;
  align-items: flex-start;
  position: relative;
  padding-top: 5.8em;
  width: 33.3333%;
}
.point:before {
  content: '';
  display: block;
  background-color: white;
  border: solid 1px black;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  position: absolute;
  top: -6px;
  left: 0;
  z-index: 1;
}
@media screen and (max-width: 768px) {
  .point:before {
    display: none;
  }
}
@media screen and (max-width: 950px) {
  .point {
    font-size: 90%;
    padding-right: 0.5em;
    padding-left: 0.5em;
  }
}
@media screen and (max-width: 768px) {
  .point {
    font-size: 100%;
    width: 50%;
    padding: 4em 0 0 0;
  }
}
@media screen and (max-width: 600px) {
  .point {
    width: 100%;
  }
}
img {
  width: 4.2em;
  height: auto;
  margin-right: 0.9em;
}
@media screen and (max-width: 600px) {
  img {
    margin-right: 1.5em;
  }
}
.text {
  flex: 1;
}
.text h2 {
  font-size: 1.4em;
  font-weight: normal;
  margin: 0 0 1em 0;
}
.text p {
  font-size: 1em;
  font-weight: normal;
  margin: 0;
  max-width: 212px;
  line-height: 1.5;
}
</style>
