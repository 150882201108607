<script>
  export let height = 100
  export let width = 300
  let yAxisWidth = 20
  let xAxisHeight = 20

  export let lines = [
    {color: 'blue', data: [10, 400, 0, 20, 30, 100, 230]}
  ]
  export let xLabels = [1, 2, 3, 4, 5, 6, 7]
  export let yLabelCount = 5

  let allData = lines.map(l => l.data).flat()
  let max = Math.max(...allData)
  let min = Math.min(...allData)
  let yLabels = Array(yLabelCount).fill().map((_, i) => min + (i * (max - min) / (yLabelCount - 1)))
  let segments = (lines[0].data.length - 1) || 1
  let xSegmentSize = width / segments
  let xSegmentPercent = 100 / segments
  let ySegmentPercent = 100 / (yLabelCount - 1)

      /* <g class='labels x-labels'> */
      /*   {#each xLabels as label, i} */
      /*     <text x='{i * xSegmentSize}' y='{height}'>{label}</text> */
      /*   {/each} */
      /* </g> */
      /* <g class='labels y-labels'> */
      /*   {#each yLabels as label, i} */
      /*     <text x='0' y='{height - (i * ySegmentSize)}'>{label}</text> */
      /*   {/each} */
      /* </g> */
  
</script>

<div class='line-graph-component' >
  <div class='y'>
    <span class='title'> Time (ms)</span>
    <div class='labels'>
      <span class='hidden-label' >{max}</span>
      {#each yLabels.reverse() as label, i}
        <span class='label' style='top: {i * ySegmentPercent}%;'>{label}</span>
      {/each}
    </div>
  </div>
  <svg viewBox='0 0 {width} {height}' class='chart'>
    <g class='grid y-grid'>
      <line x1='0' x2='0' y1='0' y2='{height}' stroke='black' ></line>
    </g>
    <g class='grid x-grid'>
      <line x1='0' x2='{width}' y1='{height}' y2='{height}' stroke='black' ></line>
    </g>
    {#each lines as line}
      <polyline
        fill='none'
        stroke='{line.color}'
        stroke-width='2'
        points='{
          line.data
            .map((v, i) => `${i * xSegmentSize}, ${height - ((v - min) / (max - min) * height)}`)
            .join('\n')
        }'
      >
        <title>{line.title}</title>
      </polyline>
    {/each}
  </svg>
  <div class='x'>
    <div class='labels'>
      {#each xLabels as label, i}
        <span class='label' style='left: {i * xSegmentPercent}%' >{label}</span>
      {/each}
    </div>
    <span class='title'>Date</span>
  </div>
</div>

<style>
  .line-graph-component {
    display: grid;
    grid-template-columns: auto 1fr;
    padding: 3em;
  }

  .x {
    grid-column-start: 2;
  }

  svg {
    overflow: visible;
  }

  .y {
    align-self: stretch;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
  }

  .y .title {
    padding-right: 1em;
  }

  .y .labels {
    align-self: stretch;
    display: flex;
    flex-flow: column nowrap;
    position: relative;
    align-items: flex-end;
    padding: 0 1em;
  }

  .y .labels span.label {
    position: absolute;
    transform: translateY(-50%);
  }

  .y .labels span.hidden-label {
    visibility: hidden;
  }

  .x {
    position: relative;
  }

  .x .labels {
    padding-top: 1em;
    height: 1em;
  }

  .x .labels .label {
    position: absolute;
    white-space: nowrap;
    transform: translateX(-50%);
  }

  .x .labels span {
    position: absolute;
  }

  .x .title {
    display: block;
    text-align: center;
    padding-top: 1em;
  }

</style>
