<script>
  import { LottiePlayer } from '@lottiefiles/svelte-lottie-player'
  import IntersectionObserver from '/elements/IntersectionObserver.svelte'
  export let lottieFile
  export let headline
  export let introText
</script>

<div class='featurette-w-points'>
  <div class='g-content-width'>

    <div class='image-copy'>

      <div class='lottie'>
      
      <IntersectionObserver let:intersecting top={0}>

        <LottiePlayer
          src={lottieFile}
          autoplay={{intersecting}}
          loop={true}
          controls={false}
          renderer="svg"
          width=''
          height=''
          background="transparent"
          controlsLayout
        />

      </IntersectionObserver>

      </div>

      <div class='copy'>
        <h2>{headline}</h2>
        <p>{introText}</p>
      </div>

    </div>

    <div class='points'>
      <slot />
    </div>

  </div>
</div>

<style lang='less'>.featurette-w-points {
  color: var(--dark-background-color);
  padding: 1em 0 6em;
}
@media screen and (max-width: 1100px) {
  .featurette-w-points {
    padding-top: 3em;
  }
}
@media screen and (max-width: 768px) {
  .featurette-w-points {
    padding-bottom: 5em;
  }
}
.featurette-w-points .image-copy {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.featurette-w-points .image-copy .lottie {
  width: 49%;
  position: relative;
  padding-bottom: 3em;
  min-height: 525px;
}
@media screen and (max-width: 768px) {
  .featurette-w-points .image-copy .lottie {
    min-height: 200px;
  }
}
@media screen and (max-width: 1100px) {
  .featurette-w-points .image-copy .lottie {
    margin-top: 0;
  }
}
.featurette-w-points .image-copy .lottie:before {
  content: '';
  display: block;
  background-color: black;
  width: 1px;
  height: 100px;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  display: none;
}
.featurette-w-points .image-copy .lottie:after {
  content: '';
  display: block;
  background-color: white;
  border: solid 1px black;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  position: absolute;
  bottom: -7px;
  left: 0;
  right: 0;
  z-index: 1;
  margin: 0 auto;
  display: none;
}
@media screen and (max-width: 1060px) {
  .featurette-w-points .image-copy .lottie:after,
  .featurette-w-points .image-copy .lottie:before {
    display: none;
  }
}
@media screen and (max-width: 768px) {
  .featurette-w-points .image-copy .lottie {
    width: 35%;
  }
}
@media screen and (max-width: 600px) {
  .featurette-w-points .image-copy .lottie {
    display: none;
  }
}
.featurette-w-points .image-copy .copy {
  width: 43%;
}
@media screen and (max-width: 1060px) {
  .featurette-w-points .image-copy .copy {
    font-size: 90%;
  }
}
@media screen and (max-width: 768px) {
  .featurette-w-points .image-copy .copy {
    width: 60%;
    font-size: 85%;
    margin-bottom: 2em;
  }
}
@media screen and (max-width: 600px) {
  .featurette-w-points .image-copy .copy {
    width: 100%;
    max-width: 400px;
    margin-left: auto;
  }
}
.featurette-w-points .image-copy .copy h2,
.featurette-w-points .image-copy .copy p {
  color: currentColor;
  padding: 0;
  margin: 0;
  text-align: right;
}
.featurette-w-points .image-copy .copy h2 {
  font-size: 2.2em;
  line-height: 1.5;
  margin-left: auto;
  font-weight: normal;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.featurette-w-points .image-copy .copy h2:after {
  display: block;
  content: '';
  width: 5em;
  height: 3px;
  margin: 1em 0 0.8em 0;
  background-color: var(--primary-accent-color);
}
.featurette-w-points .image-copy .copy p {
  font-size: 1.26em;
  line-height: 1.5;
  margin: 0 0 2em 0;
  margin-left: auto;
}
.featurette-w-points .points {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border-top: solid 1px black;
  position: relative;
}
@media screen and (max-width: 900px) {
  .featurette-w-points .points {
    font-size: 90%;
  }
}
@media screen and (max-width: 768px) {
  .featurette-w-points .points {
    flex-wrap: wrap;
    justify-content: center;
  }
}
.featurette-w-points .points:after {
  content: '';
  display: block;
  background-color: white;
  border: solid 1px black;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  position: absolute;
  top: -6px;
  right: 0;
  z-index: 1;
}
@media screen and (max-width: 768px) {
  .featurette-w-points .points:after {
    display: none;
  }
}
</style>
